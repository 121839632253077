import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import { alt, metaLoanOfficer } from '../lib/brand';
import Layout from '../components/Layout/';
import Header from '../components/Header';
import LoanOfficerDetail from '../components/LoanOfficerDetail';

const LoanOfficer = ({ data }) => {
   const { profile, siteMeta } = data;
   const {
      contact,
      title,
      profile: loProfile,
      applyNow,
      applyNowScript,
      logIn,
      team,
      blogs,
      news,
      slug,
      headBeginScript,
      headEndScript,
      bodyBeginScript,
      bodyEndScript,
      branch,
      searchVisibility
   } = profile;
   const {
      siteMetadata: { title: siteTitle }
   } = siteMeta;

   // ensure loan officers and teams are not null.
   profile.loanOfficers =
      !!loProfile.branch && Array.isArray(loProfile.branch.loanOfficers) ? loProfile.branch.loanOfficers : [];
   profile.teams = !!loProfile.branch && Array.isArray(loProfile.branch.teams) ? loProfile.branch.teams : [];
   profile.managers = !!loProfile.branch && Array.isArray(loProfile.branch.managers) ? loProfile.branch.managers : [];
   profile.staff = !!loProfile.branch && Array.isArray(loProfile.branch.staff) ? loProfile.branch.staff : [];

   let branchInfo = { ...loProfile.branch };

   const { licenseIds } = loProfile;
   let relatedProps = {
      branchInfo: branch,
      team,
      licenseIds,
      blogs,
      corporateBlogs: news,
      siteTitle,
      loanOfficers: !!branchInfo ? branchInfo.loanOfficers || [] : [],
      teams: !!branchInfo ? branchInfo.teams || [] : [],
      managers: !!branchInfo ? branchInfo.managers || [] : [],
      staff: !!branchInfo ? branchInfo.staff || [] : [],
      searchVisibility
   };

   // get dba logo from branch
   const dbaLogoBlack = get(relatedProps, 'branch.dbaLogoBlack', null);

   const followBug = {
      ...loProfile, // the profile has a profile field with contact info
      applyNow,
      applyNowScript,
      logIn,
      slug
   };

   const pageTitle = alt(`${title} - ${loProfile.jobTitle}`);
   const header = <Header active={[]} theme="white-ribbon" dbaBlack={dbaLogoBlack} clearDba={true} />;

   return (
      <Layout
         hasHero={false}
         header={header}
         setBug={true}
         loData={followBug}
         loPhoto={profile.gallery}
         headBeginScript={headBeginScript}
         headEndScript={headEndScript}
         bodyBeginScript={bodyBeginScript}
         bodyEndScript={bodyEndScript}>
         <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={metaLoanOfficer(loProfile.name, loProfile.jobTitle)} />
            <meta http-equiv="X-UA-Compatible" content="IE=11" />
            <script
               src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
         </Helmet>
         <LoanOfficerDetail {...loProfile} {...profile} {...contact} {...relatedProps} isPreview={false} />
      </Layout>
   );
};

export default LoanOfficer;

export const pageQuery = graphql`
   query LOPageById($id: String!) {
      profile(id: { eq: $id }) {
         id
         firestoreId
         slug
         headBeginScript {
            name
            src
            async
            html
         }
         headEndScript {
            name
            src
            async
            html
         }
         bodyBeginScript {
            name
            src
            async
            html
         }
         bodyEndScript {
            name
            src
            async
            html
         }
         title
         owner
         applyNow
         applyNowScript
         logIn
         profile {
            name
            email
            jobTitle
            licenseIds {
               license
               id
            }
            licenseLink
            branch {
               slug
               dbaLogoBlack
               title
               address1
               address2
               address3
               city
               state
               zip
               lat
               lon
               loanOfficers {
                  slug
                  hidden
                  gallery {
                     photo
                  }
                  profile {
                     name
                     email
                     jobTitle
                     licenseIds {
                        license
                        id
                     }
                  }
               }
               teams {
                  slug
                  title
                  profile {
                     email
                     licenseIds {
                        license
                        id
                     }
                  }
               }
               staff {
                  photo
                  name
                  title
                  license
                  email
               }
               managers {
                  photo
                  name
                  title
                  license
                  email
               }
            }
         }
         blogs {
            id
            html
            slug
            title
            author {
               slug
               profile {
                  name
               }
               gallery {
                  photo
               }
            }
            date
            feature
            categories
            bloghero
            image
         }
         news {
            id
            html
            slug
            title
            date
            feature
            categories
            image
         }
         hero {
            quote
            byline
            customPhoto
            heroBackground
            photoAlignment
         }
         gallery {
            photo
            image1
            image2
            image3
         }
         contact {
            phone
            mobilePhone
            fax
            facebook
            instagram
            linkedin
            twitter
            youtube
         }
         modules {
            type
            display
            videos {
               videoTitle
               videoCaption
               videoUrl
            }
            facebookPageUrl
            bookings
            calendar
            useBackgroundImage
            backgroundImage
            backgroundImageCustom
            darkTitleText
            instagramToken
            resourceIntro
            resourceList {
               resourceFile
               resourceUrl
               resourceDescription
            }
            event1 {
               eventImage
               eventCustomImage
               eventName
               eventDate
               eventTime
               eventLocation
               eventDescription
               eventUrl
               eventCta
            }
            event2 {
               eventImage
               eventCustomImage
               eventName
               eventDate
               eventTime
               eventLocation
               eventDescription
               eventUrl
               eventCta
            }
            event3 {
               eventImage
               eventCustomImage
               eventName
               eventDate
               eventTime
               eventLocation
               eventDescription
               eventUrl
               eventCta
            }
            awards {
               awardDescription
               awardYear
               awardImage
               awardBadge
            }
            social_id
            social_page
            showCalculatorMortgage
            showCalculatorRentVsBuy
            showCalculatorHomeAffordability
            showCalculatorRequiredIncome
            showCalculatorRefinance
            contentImage
            contentTitle
            contentText
            profileShowCompactView
            profileTitle
            profileBody
            contactFormFields {
               type
               required
               customFieldType
               label
               bammName
               options {
                  hasCustomLabel
                  label
                  value
               }
            }
            contactFormTitle
            contactUsText
            contactUsTitle
            contactUsBammUrl
            contactUsLeadEmailCcList
            contactUsConfirmEmailText
            contactUsConfirmEmailFrom
            overrideAddress
            overrideAddressText
            licensedInText
            id
         }
         searchVisibility
      }
      siteMeta: site {
         siteMetadata {
            title
         }
      }
   }
`;
